import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export async function login(data) {
  try {
    return await axios.post("/login", data).then((res) => {
      toast.success(res.data?.message);
      return res;
    });
  } catch (err) {
    if (err.response.status === 401) {
      toast.error("아이디 또는 비밀번호가 일치하지 않습니다.");
    } else {
      toast.error(err.message);
    }
  }
}

export async function register(data) {
  try {
    return await axios.post("/signup", data).then((res) => {
      toast.success(res.data);
      return res;
    });
  } catch (err) {
    toast.error(err.message);
  }
}

export async function getTags() {
  return await axios.get("/tag/").then((res) => {
    return res.data;
  });
}

export async function getProblemList(params) {
  return await axios
    .get("/problem/list", {
      params,
    })
    .then((res) => {
      return res.data;
    });
}

export async function getProblem(problem_num) {
  return await axios.get(`/problem/${problem_num}`).then((res) => {
    return res.data;
  });
}

export async function submitCode({ language, problem_id, src }) {
  return await axios
    .post("/submission/create", {
      language,
      problem_id,
      src,
    })
    .then((res) => {
      return res.data;
    });
}

export async function getSubmissionList(params) {
  return await axios
    .get("/submission/list", {
      params,
    })
    .then((res) => {
      return res.data;
    });
}

export async function getSubmission(submission_id) {
  return await axios.get(`/submission/${submission_id}`).then((res) => {
    return res.data;
  });
}
