import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export async function createProblem(data) {
  return await axios.post("/problem/create", data).then((res) => {
    return res.data;
  });
}

export async function getAdminProblemList(params) {
  return await axios.get("/problem/admin/list", { params }).then((res) => {
    return res.data;
  });
}

export async function getTags() {
  return await axios.get("/tag/").then((res) => {
    return res.data;
  });
}

export async function deleteProblem(id) {
  return await axios.delete(`/problem/delete/${id}`).then((res) => {
    return res.data;
  });
}

export async function toggleProblemVisible(id) {
  return await axios.patch(`/problem/toggle-visible/${id}`).then((res) => {
    return res.data;
  });
}
