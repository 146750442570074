import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";
import { theme } from "./theme";
import AdminRouter from "./pages/admin/index.jsx";
import OjRouter from "./pages/oj/router/index.jsx";
import Modal from "./components/Modal.jsx";

function App() {
  return (
    <div>
      <ThemeProvider value={theme}>
        <RecoilRoot>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Router>
              <OjRouter />
              <AdminRouter />
            </Router>
            <Modal />
            <Toaster containerStyle={{ zIndex: 99999 }} />
          </React.Suspense>
        </RecoilRoot>
      </ThemeProvider>
    </div>
  );
}

export default App;
