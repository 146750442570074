import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-tailwind/react";

const Sample = ({ sampleList, setSampleList }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <Button
        color="blue"
        size="sm"
        onClick={() =>
          setSampleList([...sampleList, { input: "", output: "" }])
        }
        className="ml-auto my-2"
      >
        {t("m.Add_Sample")}
      </Button>
      {sampleList &&
        sampleList.map((item, i) => (
          <div key={i} className="mb-4">
            <section className="flex flex-row justify-between bg-white w-full h-12 border border-gray-300 p-2">
              <text>Sample {i + 1}</text>
              <Button
                size="sm"
                className="bg-orange-400"
                onClick={() =>
                  setSampleList(sampleList.filter((_, index) => index !== i))
                }
              >
                delete
              </Button>
            </section>
            <section className="grid grid-cols-2 gap-4 bg-blue-gray-50  border border-gray-300 p-2">
              <div>
                <span className="block text-sm font-medium text-slate-700 py-2">
                  {t("m.Input_Samples")}
                </span>
                <textarea
                  className="flex w-full h-auto min-h-[80px] px-3 py-2 text-sm bg-white border rounded-md border-neutral-300 ring-offset-background placeholder:text-neutral-400 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                  placeholder={t("m.Input_Samples")}
                  value={item.input}
                  onChange={(e) => {
                    let temp = [...sampleList];
                    temp[i].input = e.target.value;
                    setSampleList(temp);
                  }}
                />
              </div>
              <div>
                <span className="block text-sm font-medium text-slate-700 py-2">
                  {t("m.Output_Samples")}
                </span>
                <textarea
                  className="flex w-full h-auto min-h-[80px] px-3 py-2 text-sm bg-white border rounded-md border-neutral-300 ring-offset-background placeholder:text-neutral-400 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                  placeholder={t("m.Output_Samples")}
                  value={item.output}
                  onChange={(e) => {
                    let temp = [...sampleList];
                    temp[i].output = e.target.value;
                    setSampleList(temp);
                  }}
                />
              </div>
            </section>
          </div>
        ))}
    </div>
  );
};

export default Sample;
