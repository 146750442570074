export const Result = {
  WrongAnswer: { message: "WrongAnswer", color: "red" },
  Success: { message: "Success", color: "green" },
  CpuTimeLimitExceeded: {
    message: "CpuTimeLimitExceeded",
    color: "orange",
  },
  RealTimeLimitExceeded: {
    message: "RealTimeLimitExceeded",
    color: "orange",
  },
  MemoryLimitExceeded: { message: "MemoryLimitExceeded", color: "orange" },
  RuntimeError: { message: "RuntimeError", color: "orange" },
  SystemError: { message: "SystemError", color: "orange" },
  CompileError: { message: "CompileError", color: "orange" },
  JudgeClientError: { message: "JudgeClientError", color: "orange" },
};
