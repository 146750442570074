import React from "react";
import { Button, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { register } from "../../api";
import { useModal } from "../../../../hooks/useModal";
import Login from "./Login";
import { WEBSITE_CONFIG } from "../../../../components/TopNav";

function Register() {
  const { t } = useTranslation();
  const { closeModal, openModal } = useModal();
  const {
    register: formRegister,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();

  const loginModalData = {
    title: `${t("m.Welcome_to")} ${WEBSITE_CONFIG.website_name_shortcut}`,
    content: <Login />,
    size: "xs",
  };

  const handleRegister = ({
    username,
    loginId,
    email,
    password,
    passwordAgain,
  }) => {
    if (password !== passwordAgain) {
      setError("passwordAgain", { message: t("m.password_does_not_match") });
      return;
    }

    register({ username, login_id: loginId, email, password }).then((res) => {
      if (res?.status === 200) {
        closeModal();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <div className="flex flex-col space-y-4">
        <Input
          type="text"
          size="lg"
          label={t("m.RegisterUsername")}
          {...formRegister("username", { required: true })}
        />
        <Input
          type="text"
          size="lg"
          label={t("m.RegisterLoginId")}
          {...formRegister("loginId", { required: true })}
        />
        <Input
          type="email"
          size="lg"
          label={t("m.Email_Address")}
          {...formRegister("email", { required: true })}
        />
        <Input
          type="password"
          size="lg"
          label={t("m.RegisterPassword")}
          {...formRegister("password", { required: true })}
        />
        <div>
          <Input
            type="password"
            size="lg"
            label={t("m.Password_Again")}
            error={errors.passwordAgain}
            {...formRegister("passwordAgain", { required: true })}
          />
          {errors.passwordAgain && (
            <p className="pt-1 text-xs text-red-500">
              {errors.passwordAgain.message}
            </p>
          )}
        </div>
        {/* NOTE: 캡챠 기능 필요 유무 협의 후 코드 수정 예정
        <div className="flex justify-between h-11">
          <Input
            type="text"
            size="lg"
            label={t("m.Captcha")}
            {...register("captcha", { required: true })}
          />
          <Tooltip content="Click to refresh" placement="top">
            <img
              className="p-1 ml-3"
              src={data.captchaSrc}
              onClick={() => {}}
            />
          </Tooltip>
        </div> */}
      </div>
      <div className="flex flex-col mt-5 space-y-4 overflow-auto">
        <Button variant="filled" type="submit">
          {t("m.UserRegister")}
        </Button>
        <Button
          color="gray"
          variant="outlined"
          onClick={() => openModal(loginModalData)}
        >
          {t("m.Already_Registed")}
        </Button>
      </div>
    </form>
  );
}

export default Register;
