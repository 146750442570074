import React from "react";
import { IconButton } from "@material-tailwind/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

function Pagination({ totalPage }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getItemProps = (index) => ({
    variant: "filled",
    color: Number(searchParams.get("page") ?? 1) === index ? "blue" : "white",
    onClick: () =>
      setSearchParams({ ...Object.fromEntries(searchParams), page: index }),
  });

  const next = () => {
    if (Number(searchParams.get("page") ?? 1) === totalPage) return;

    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: Number(searchParams.get("page") ?? 1) + 1,
    });
  };

  const prev = () => {
    if (Number(searchParams.get("page") ?? 1) === 1) return;

    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: Number(searchParams.get("page") ?? 1) - 1,
    });
  };

  return (
    <div className="flex items-center gap-4">
      <IconButton
        variant="filled"
        color="white"
        className="flex items-center gap-2"
        onClick={prev}
        disabled={Number(searchParams.get("page") ?? 1) === 1}
      >
        <ChevronLeftIcon strokeWidth={2} className="w-4 h-4" />
      </IconButton>
      <div className="flex items-center gap-2">
        {Array.from({ length: totalPage }, (v, i) => i + 1).map((index) => (
          <IconButton key={index} {...getItemProps(index)}>
            {index}
          </IconButton>
        ))}
      </div>
      <IconButton
        variant="filled"
        color="white"
        className="flex items-center gap-2"
        onClick={next}
        disabled={Number(searchParams.get("page") ?? 1) === totalPage}
      >
        <ChevronRightIcon strokeWidth={2} className="w-4 h-4" />
      </IconButton>
    </div>
  );
}

export default Pagination;
