import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./routes";
import Layout from "../../../components/Layout";

function OjRouter() {
  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        {routes.map((route, i) => (
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
    </Routes>
  );
}

export default OjRouter;
