import { atom } from "recoil";

export const modalState = atom({
  key: "modalStatus",
  default: {
    open: false,
    title: "",
    content: "",
    size: "sm", // "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
    btnName: undefined,
    callBack: undefined,
  },
});
