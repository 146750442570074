import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { modalState } from "../store/states/modalState";

export const useModal = () => {
  const [modalDataState, setModalDataState] = useRecoilState(modalState);

  const closeModal = useCallback(
    () => setModalDataState((prev) => ({ ...prev, open: false })),
    [setModalDataState],
  );

  const openModal = useCallback(
    ({ title, content, size = "sm", btnName, callback }) =>
      setModalDataState({
        open: true,
        title: title,
        content: content,
        size: size,
        btnName: btnName,
        callBack: callback,
      }),
    [setModalDataState],
  );

  return { modalDataState, closeModal, openModal };
};
