import ProblemList from "./views/problem/ProblemList";
import AddPublicProblem from "./views/problem/AddPublicProblem";

export default [
  {
    path: "/admin/problem/problemList",
    meta: { title: "Problem List" },
    component: ProblemList,
  },
  {
    path: "/admin/problem/addPublicProblem",
    meta: { title: "Create Problem" },
    component: AddPublicProblem,
  },
];
