import React, { Children } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

function Panel({ children }) {
  let title, extra, content;

  Children.forEach(children, (child) => {
    switch (child.type) {
      case Panel.Title:
        title = child;
        break;
      case Panel.Extra:
        extra = child;
        break;
      default:
        content = child;
        break;
    }
  });

  return (
    <Card>
      <CardHeader
        floated={false}
        shadow={false}
        className="overflow-visible rounded-none"
      >
        <div className="flex flex-col justify-between gap-8 px-4 md:flex-row md:items-center">
          {title}
          {extra}
        </div>
      </CardHeader>
      <CardBody>{content ?? ""}</CardBody>
    </Card>
  );
}

Panel.Title = function Title({ children }) {
  return (
    <>
      {children && (
        <Typography variant="h4" color="blue-gray">
          {children}
        </Typography>
      )}
    </>
  );
};

Panel.Extra = function Extra({ children }) {
  return (
    <>
      {children && (
        <div className="flex items-center w-full gap-2 shrink-0 md:w-max">
          {children}
        </div>
      )}
    </>
  );
};

export default Panel;
