import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className="flex flex-col items-center">
      <TopNav />
      <div className="w-screen px-6 xl:mt-5 mt-28 xl:px-9">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
