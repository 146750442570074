import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const languages = [{ value: "ko-KR", label: "한국어" }];

const resources = {};

for (let lang of languages) {
  let locale = lang.value;
  let m = require(`./oj/${locale}`).m;
  Object.assign(m, require(`./admin/${locale}`).m);
  resources[locale] = Object.assign({ translation: { m } });
}

i18n.use(initReactI18next).init({
  resources,
  lng: "ko-KR",
  fallbackLng: "ko-KR",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
export { languages };
