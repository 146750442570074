export const theme = {
  button: {
    defaultProps: {
      color: "blue",
    },
    styles: {
      base: {
        initial: {
          textTransform: undefined,
        },
      },
    },
  },
  chip: {
    styles: {
      base: {
        chip: {
          textTransform: undefined,
        },
      },
    },
  },
};
