export const m = {
  // NavBar.vue
  Home: "Home",
  NavProblems: "Problems",
  Contests: "Contests",
  NavSubmissions: "Submissions",
  Rank: "Rank",
  ACM_Rank: "ACM Rank",
  OI_Rank: "OI Rank",
  About: "About",
  Judger: "Judger",
  FAQ: "FAQ",
  Login: "Login",
  Register: "Register",
  MyHome: "Home",
  Settings: "Settings",
  Management: "Management",
  Logout: "Logout",
  Welcome_to: "Welcome to",
  // Problem.vue
  Description: "Description",
  Input: "Input",
  Output: "Output",
  Sample_Input: "Sample Input",
  Sample_Output: "Sample Output",
  Hint: "Hint",
  Source: "Source",
  Status: "Status",
  Information: "Information",
  Time_Limit: "Time Limit",
  Memory_Limit: "Memory Limit",
  Created: "Created By",
  Level: "Level",
  Score: "Score",
  Tags: "Tags",
  Show: "Show",
  Submit: "Submit",
  Submitting: "Submitting",
  Judging: "Judging",
  Wrong_Answer: "Wrong Answer",
  Statistic: "Statistic",
  Close: "Close",
  View_Contest: "View Contest",
  Are_you_sure_you_want_to_reset_your_code:
    "Are you sure you want to reset your code?",
  Code_can_not_be_empty: "Code can not be empty",
  Submit_code_successfully: "Submit code successfully",
  You_have_solved_the_problem: "You have solved the problem",
  Submitted_successfully: "Submitted successfully",
  You_have_submitted_a_solution: "You have submitted a solution.",
  Contest_has_ended: "Contest has ended",
  You_have_submission_in_this_problem_sure_to_cover_it:
    "You have submission in this problem, sure to cover it?",
  // ProblemList.vue
  Problem_List: "Problem List",
  High: "High",
  Mid: "Mid",
  Low: "Low",
  All: "All",
  Reset: "Reset",
  Pick_One: "Pick one",
  Difficulty: "Difficulty",
  Total: "Total",
  AC_Rate: "AC Rate",
  // Register.vue
  RegisterUsername: "Username",
  RegisterLoginId: "ID",
  Email_Address: "Email Address",
  RegisterPassword: "Password",
  Password_Again: "Password Again",
  Captcha: "Captcha",
  UserRegister: "Register",
  Already_Registed: "Already registed? Login now!",
  The_username_already_exists: "The username already exists.",
  The_email_already_exists: "The email already exists",
  password_does_not_match: "password does not match",
  Thanks_for_registering: "Thanks for your registering, you can login now",
  // Login.vue
  LoginUsername: "Username",
  LoginId: "ID",
  LoginPassword: "Password",
  TFA_Code: "Code from your TFA app",
  No_Account: "No account? Register now!",
  Forget_Password: "Forget Password",
  UserLogin: "Login",
  Welcome_back: "Welcome back to OJ",
  // ContestDetail.vue
  Problems: "Problems",
  Announcements: "Announcements",
  Submissions: "Submissions",
  Rankings: "Rankings",
  Overview: "Overview",
  Admin_Helper: "Admin Helper",
  StartAt: "StartAt",
  EndAt: "EndAt",
  ContestType: "ContestType",
  Creator: "Creator",
  Public: "Public",
  Password_Protected: "Password Protected",
  Mine: "Mine",
};
