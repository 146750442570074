import React from "react";

const Footer = () => {
  return (
    <div className="mx-0 mt-5 mb-3">
      <footer>
        00000 ⓒ 2024. All rights reserved. | Contact 000-000-0000~0, 0000 |
        (00000) 0000시 00구 00로00번길 0
      </footer>
    </div>
  );
};

export default Footer;
