import React from "react";
import { Routes, Route } from "react-router-dom";
import router from "./router";
import Home from "./Home";

function AdminRouter() {
  return (
    <Routes>
      <Route path={"/admin"} element={<Home />}>
        {router.map((route, i) => (
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
    </Routes>
  );
}

export default AdminRouter;
