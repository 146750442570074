import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { ShoppingBagIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function SideMenu() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="sticky top-0 left-0 bg-white w-full h-screen max-w-[17rem] p-4">
      <div className="mb-2 p-4">
        <Typography className="flex justify-center">
          <Logo />
        </Typography>
      </div>

      <List>
        <Accordion
          open={open === 2}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 2 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 2}>
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                {t("m.Problem")}
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="pl-12">
              <ListItem>
                <Link className="w-full h-full" to="/admin/problem/problemList">
                  {t("m.Problem_List")}
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  className="w-full h-full"
                  to="/admin/problem/addPublicProblem"
                >
                  {t("m.Create_Problem")}
                </Link>
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
      </List>
    </div>
  );
}

export default SideMenu;
