import { Home } from "../views";
import { ProblemList, ProblemDetail } from "../views";
import Submission from "../views/submission/Submission";
import SubmissionDetail from "../views/submission/SubmissionDetail";

export default [
  {
    path: "/",
    meta: { title: "Home" },
    component: Home,
  },
  {
    path: "/problem",
    meta: { title: "Problem List" },
    component: ProblemList,
  },
  {
    path: "/problem/:problem_num",
    meta: { title: "Problem Detail" },
    component: ProblemDetail,
  },
  {
    path: "/submission",
    meta: { title: "Submission" },
    component: Submission,
  },
  {
    path: "/submission/:submission_id",
    meta: { title: "Submission Detail" },
    component: SubmissionDetail,
  },
];
