import React from "react";
import {
  Dialog,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { useModal } from "../hooks/useModal";
import { XMarkIcon } from "@heroicons/react/24/outline";

function Modal() {
  const { modalDataState, closeModal } = useModal();

  return (
    <Dialog
      size={modalDataState.size}
      open={modalDataState.open}
      handler={closeModal}
    >
      <DialogHeader className="justify-between">
        <Typography variant="h5">{modalDataState.title}</Typography>
        <IconButton variant="text" size="sm" onClick={closeModal}>
          <XMarkIcon className="w-5 h-5" />
        </IconButton>
      </DialogHeader>
      <DialogBody className="text-base font-normal">
        {modalDataState.content}
      </DialogBody>
      {modalDataState.btnName && (
        <DialogFooter>
          <Button onClick={modalDataState.callBack}>
            {modalDataState.btnName}
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}

export default Modal;
