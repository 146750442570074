import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@material-tailwind/react";
import { TagsInput } from "react-tag-input-component";
import Sample from "./Sample";
import { createProblem } from "../../api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Panel from "../../components/Panel";

const defaultProblem = {
  title: "",
  content: "",
  input_desc: "",
  output_desc: "",
  input_ex: "",
  output_ex: "",
  limit_memory: 0,
  limit_run_time: 0,
  difficulty: "",
  hint: "",
  visible: true,
  tags: [],
  testcases: [
    {
      input: "",
      output: "",
    },
  ],
};

function AddPublicProblem() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const descriptions = [
    {
      title: t("m.ContestDescription"),
      key: "content",
    },
    {
      title: t("m.Input_Description"),
      key: "input_desc",
    },
    {
      title: t("m.Output_Description"),
      key: "output_desc",
    },
  ];
  const [problem, setProblem] = useState(defaultProblem);
  const limits = [
    {
      title: t("m.Time_Limit") + " (ms)",
      key: "limit_run_time",
    },
    {
      title: t("m.Memory_limit") + " (MB)",
      key: "limit_memory",
    },
  ];
  const submitHandler = () => {
    for (const key in problem) {
      if (key !== "visible" && problem[key] === defaultProblem[key]) {
        console.log(key);
        console.log(problem.value);
        return toast.error("모든 항목을 입력해주세요.");
      }
    }
    createProblem(problem).then(() => {
      toast.success("문제가 성공적으로 추가되었습니다.");
      navigate("/admin/problem/problemList");
    });
  };

  return (
    <Panel>
      <div className="mx-auto w-full p-4 bg-white grid grid-cols-1 divide-y-2 divide-gray-200">
        <Panel.Title>{t("m.Add_Problem")}</Panel.Title>
        <div className="grid grid-cols-6 gap-4">
          <form className="col-span-4">
            <label className="block">
              <span className="block text-sm font-medium text-slate-700 py-2">
                {t("m.ContestTitle")}
              </span>
              <input
                value={problem.title}
                onChange={(e) =>
                  setProblem({ ...problem, title: e.target.value })
                }
                type="text"
                className="border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
              />
            </label>
          </form>
          {descriptions.map((descript, index) => {
            return (
              <div key={index} className="col-span-6">
                <span className="block text-sm font-medium text-slate-700 py-2">
                  {descript.title}
                </span>
                <textarea
                  className="resize-none border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
                  value={problem?.[descript.key]}
                  onChange={(event) => {
                    setProblem({
                      ...problem,
                      [descript.key]: event.target.value,
                    });
                  }}
                />
              </div>
            );
          })}
          <div className="col-span-3">
            <span className="block text-sm font-medium text-slate-700 py-2">
              {t("m.Sample_Input")}
            </span>
            <textarea
              value={problem.input_ex}
              onChange={(event) => {
                setProblem({
                  ...problem,
                  input_ex: event.target.value,
                });
              }}
              className="resize-none border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
            />
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-medium text-slate-700 py-2">
              {t("m.Sample_Output")}
            </span>
            <textarea
              value={problem.output_ex}
              onChange={(event) => {
                setProblem({
                  ...problem,
                  output_ex: event.target.value,
                });
              }}
              className="resize-none border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
            />
          </div>
          {limits.map((limit, index) => {
            return (
              <form key={index} className="col-span-2">
                <label className="block">
                  <span className="block text-sm font-medium text-slate-700 py-2">
                    {limit.title}
                  </span>
                  <input
                    id={limit.key}
                    value={problem[limit.key]}
                    onChange={(e) =>
                      setProblem({
                        ...problem,
                        [limit.key]: e.target.value,
                      })
                    }
                    type="text"
                    className="border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
                  />
                </label>
              </form>
            );
          })}
          <form className="col-span-2">
            <label className="block">
              <span className="block text-sm font-medium text-slate-700 py-2">
                {t("m.Difficulty")}
              </span>
              <select
                value={problem.difficulty}
                onChange={(e) =>
                  setProblem({
                    ...problem,
                    difficulty: e.target.value,
                  })
                }
                name="difficulty"
                className="border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
              >
                <option value="" selected disabled hidden>
                  {t("m.Difficulty")}
                </option>
                <option value="low">{t("m.Low")}</option>
                <option value="mid">{t("m.Mid")}</option>
                <option value="high">{t("m.High")}</option>
              </select>
            </label>
          </form>
          <div className="col-span-2">
            <span className="block text-sm font-medium text-slate-700 py-2">
              {t("m.Announcement_visible")}
            </span>
            <Switch
              checked={problem.visible}
              onChange={(e) =>
                setProblem({ ...problem, visible: e.target.checked })
              }
              color="blue"
            />
          </div>
          <form className="col-span-2">
            <label className="block">
              <span className="block text-sm font-medium text-slate-700 py-2">
                {t("m.Tag")}
              </span>
              <TagsInput
                className="border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
                value={problem?.tags || []}
                onChange={(tags) => {
                  const tagSet = new Set(tags);
                  setProblem({ ...problem, tags: Array.from(tagSet) });
                }}
                placeHolder={t("m.New_Tag")}
              />
            </label>
          </form>
          <div className="col-span-6">
            <span className="block text-sm font-medium text-slate-700 py-2">
              {t("m.Hint")}
            </span>
            <textarea
              className="resize-none border border-gray-300 text-gray-900 text-sm rounded-md focus:border-2 focus:outline-none focus:ring-0 block w-full p-1"
              value={problem?.hint}
              onChange={(event) => {
                setProblem({
                  ...problem,
                  hint: event.target.value,
                });
              }}
            />
          </div>
          <div className="col-span-6">
            <Sample
              className="col-span-6"
              sampleList={problem?.testcases || []}
              setSampleList={(newList) =>
                setProblem((prev) => ({ ...prev, testcases: newList }))
              }
            />
          </div>
          <div className="col-span-6 flex flex-col">
            <button
              onClick={submitHandler}
              className="bg-blue-400 mt-auto ml-auto py-2 px-4 rounded-lg text-white text-base"
            >
              {t("m.Submit")}
            </button>
          </div>
        </div>
      </div>
    </Panel>
  );
}

export default AddPublicProblem;
