import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel";
import {
  Button,
  Card,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  Bars4Icon,
  ClipboardDocumentListIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { InformationCircleIcon, ChartPieIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-text";

import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { getProblem, submitCode } from "../../api";
import PieChart from "../../components/PieChart";
import { Result } from "../../../../common/enum";

const PROBLEM_Detail = [
  {
    name: "Description",
    key: "content",
  },
  {
    name: "Hint",
    key: "hint",
  },
  {
    name: "Input",
    key: "input_desc",
  },
  {
    name: "Output",
    key: "output_desc",
  },
];

const PROBLEM_INFO = [
  {
    name: "ID",
    key: "_id",
  },
  {
    name: "Time Limit",
    key: "limit_run_time",
  },
  {
    name: "Memory Limit",
    key: "limit_memory",
  },
  {
    name: "Created By",
    key: "created_by",
  },
  {
    name: "Level",
    key: "difficulty",
  },
];

const language_mods = {
  c: "c_cpp",
  cpp: "c_cpp",
  py: "python",
  java: "java",
};

function ProblemDetail() {
  const { problem_num } = useParams();
  const [problem, setProblem] = useState();

  const [code, setCode] = useState("");
  const [language, setLanguage] = useState("");
  const [result, setResult] = useState(null);

  const submitHandler = () => {
    submitCode({ language, problem_id: problem.id, src: code }).then((data) => {
      setResult(Result?.[data]);
      getProblem(problem_num).then((res) => {
        setProblem(res);
      });
    });
  };

  console.log(result);

  useEffect(() => {
    getProblem(problem_num).then((res) => {
      setProblem(res);
    });
  }, [problem_num]);

  if (!problem) return null;
  return (
    <div className="flex gap-5">
      <section className="w-full min-w-96 flex flex-col gap-5">
        <Panel>
          <Panel.Title>{problem.title}</Panel.Title>
          <div className="w-full px-5">
            {PROBLEM_Detail.map(
              (info) =>
                problem[info.key] && (
                  <div key={info.key} className="py-3">
                    <Typography variant="h5" color="blue">
                      {info.name}
                    </Typography>
                    <p className="pl-5">{problem[info.key]}</p>
                  </div>
                ),
            )}
            <div className="grid grid-cols-2 gap-8">
              <div className="py-3">
                <Typography
                  variant="h5"
                  color="blue"
                  className="flex items-center"
                >
                  Sample Input
                  <button>
                    <ClipboardDocumentListIcon className="w-5 h-5 ml-2" />
                  </button>
                </Typography>
                <p className="px-2 py-1 w-full border mt-3">
                  {problem.input_ex}
                </p>
              </div>
              <div className="py-3">
                <Typography variant="h5" color="blue">
                  Sample Output
                </Typography>
                <p className="px-2 py-1 w-full border mt-3">
                  {problem.output_ex}
                </p>
              </div>
            </div>
          </div>
        </Panel>
        <Card className="bg-white py-3 px-5">
          <div className="flex w-full gap-12 mb-5">
            <div className="w-40">
              <Select
                value={language}
                onChange={(e) => setLanguage(e)}
                label="Language"
              >
                <Option value="c">C</Option>
                <Option value="cpp">C++</Option>
                <Option value="py">Python</Option>
                <Option value="java">Java</Option>
              </Select>
            </div>
            <button
              onClick={() => setCode("")}
              className="bg-gray-100 border border-gray-300 group hover:bg-white hover:border-blue-500 p-3 rounded transition-colors duration-500"
            >
              <ArrowPathIcon className="w-3 h-3 group-hover:stroke-blue-500" />
            </button>
          </div>
          <div className="flex flex-col gap-5 items-end">
            <AceEditor
              width="100%"
              className="problem-code-input border-b border-r"
              placeholder={language === "" ? "Select Language" : "Write Code"}
              readOnly={language === ""}
              mode={language_mods[language] || "text"}
              theme="tomorrow"
              name="codeInput"
              fontSize={18}
              showPrintMargin
              showGutter
              highlightActiveLine
              value={code}
              onChange={(value) => setCode(value)}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
            <div className="flex w-full">
              {result && (
                <Typography
                  variant="h6"
                  color={result.color}
                  className="flex-1"
                >
                  {result.message}
                </Typography>
              )}
              <Button
                color="orange"
                className="w-20 ml-auto"
                onClick={submitHandler}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </section>
      <section className="w-80 flex flex-col gap-5">
        <Link to={`/submission?problemNumber=${problem_num}`}>
          <Card className="bg-white hover:bg-opacity-50 py-3 px-5 hover:border-l-4 border-blue-500 group">
            <Typography
              variant="h6"
              color="black"
              className="flex items-center group-hover:!text-blue-500"
            >
              <Bars4Icon className="w-5 h-5 mr-2" />
              Submissions
            </Typography>
          </Card>
        </Link>
        <Card className="bg-white py-3 px-5">
          <p className="text-sm flex mb-5">
            <InformationCircleIcon className="w-4 h-4 mr-2" />
            Information
          </p>
          {PROBLEM_INFO.map(
            (info) =>
              problem[info.key] && (
                <div
                  key={info.key}
                  className="flex justify-between border-b my-2"
                >
                  <p>{info.name}</p>
                  <p>{problem[info.key]}</p>
                </div>
              ),
          )}
          {problem?.tags?.length > 0 && (
            <div className="flex justify-between border-b my-2">
              <p>Tags</p>
              <Tooltip
                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                content={
                  <div className="flex gap-2">
                    {problem.tags.map((tag) => (
                      <Typography
                        key={tag}
                        variant="small"
                        color="black"
                        className="border px-2 py-1 rounded bg-blue-gray-50"
                      >
                        {tag}
                      </Typography>
                    ))}
                  </div>
                }
                placement="left"
              >
                <p className="text-blue-500">Show</p>
              </Tooltip>
            </div>
          )}
        </Card>
        <Card className="bg-white py-3 px-5">
          <p className="text-sm flex mb-5">
            <ChartPieIcon className="w-4 h-4 mr-2" />
            Statistics
          </p>
          <PieChart
            data={[
              { name: "AC", value: problem.correct, color: "#1BCF74" },
              { name: "WA", value: problem.wrong, color: "#FF4516" },
            ]}
          />
        </Card>
      </section>
    </div>
  );
}

export default ProblemDetail;
