import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  ButtonGroup,
  Chip,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Switch } from "@material-tailwind/react";
import Panel from "../../components/Panel";
import Pagination from "../../components/Pagination";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteProblem,
  getAdminProblemList,
  getTags,
  toggleProblemVisible,
} from "../../api";

export function SwitchCustomStyles({ checked, onChange }) {
  const { t } = useTranslation();

  return (
    <Switch
      color="blue"
      checked={checked}
      onChange={onChange}
      ripple={false}
      className="w-full h-full"
      containerProps={{
        className: "w-14 h-7 -mr-3",
      }}
      label={
        <div className="relative flex items-center">
          <span className="absolute text-[0.6rem] font-medium text-white right-[0.5rem]">
            {t("m.Tags")}
          </span>
          <span className="absolute text-[0.6rem] font-medium text-white right-[1.9rem]">
            {t("m.Tags")}
          </span>
        </div>
      }
      circleProps={{
        className: "before:hidden left-1 w-6 border-none",
      }}
    />
  );
}

function ProblemList() {
  const [tags, setTags] = useState(null);
  const [problemList, setProblemList] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [difficulty, setDifficulty] = useState("All");
  const [currentTag, setCurrentTag] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [switchOn, toggleSwitch] = useReducer((state) => !state, false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm();

  const TABLE_HEAD = [
    "#",
    t("m.Title"),
    t("m.Level"),
    t("m.Total"),
    t("m.AC_Rate"),
    t("m.Tags"),
    "",
  ];

  useEffect(() => {
    getAdminProblemList({
      difficulty: difficulty === "All" ? null : difficulty,
      tag: currentTag,
      search: currentSearch,
      page: Number(searchParams.get("page") ?? 1) - 1,
    }).then((data) => {
      setProblemList(data?.results);
      setTotalPage(data?.total_page);
    });
  }, [difficulty, currentTag, currentSearch, searchParams.get("page")]);

  useEffect(() => {
    getTags().then((data) => {
      setTags(data);
    });
  }, []);

  const handleSearch = ({ search }) => {
    setCurrentSearch(search);
  };

  const handleResetClick = () => {
    reset();
    setCurrentSearch(null);
    setCurrentTag(null);
    setDifficulty(null);
  };

  const handleDeleteProblem = (problem_number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteProblem(problem_number).then((data) => {
        if (data) {
          alert("삭제되었습니다.");
          setProblemList((prev) =>
            prev.filter((problem) => problem.problem_number !== problem_number),
          );
        }
      });
    }
  };

  const handelToggleProblemVisible = (problem_number) => {
    toggleProblemVisible(problem_number).then((data) => {
      if (data) {
        setProblemList((prev) =>
          prev.map((problem) =>
            problem.problem_number === problem_number
              ? { ...problem, visible: !problem.visible }
              : problem,
          ),
        );
      }
    });
  };

  return (
    <div className="grid gap-5 grid-cols-24">
      <section className="col-span-19">
        <Panel>
          <Panel.Title>{t("m.Problem_List")}</Panel.Title>
          <Panel.Extra>
            <form
              className="flex items-center space-x-2"
              onSubmit={handleSubmit(handleSearch)}
            >
              <SwitchCustomStyles checked={switchOn} onChange={toggleSwitch} />
              <div className="w-24">
                <Select
                  containerProps={{ className: "!min-w-20" }}
                  className="z-10"
                  label={t("m.Difficulty")}
                  value={difficulty}
                  onChange={(newValue) => {
                    setDifficulty(newValue);
                  }}
                >
                  <Option value={t("m.All")}>{t("m.All")}</Option>
                  <Option value={t("m.Low")}>{t("m.Low")}</Option>
                  <Option value={t("m.Mid")}>{t("m.Mid")}</Option>
                  <Option value={t("m.High")}>{t("m.High")}</Option>
                </Select>
              </div>
              <div className="w-full md:w-60">
                <Input
                  className="h-fit"
                  label="Search"
                  icon={
                    <MagnifyingGlassIcon className="w-4 h-4" type="submit" />
                  }
                  {...register("search")}
                />
              </div>
              <Button
                className="flex items-center gap-3 h-fit"
                onClick={handleResetClick}
              >
                <ArrowPathIcon strokeWidth={2} className="w-4 h-4" />
                {t("m.Reset")}
              </Button>
            </form>
          </Panel.Extra>
          <table className="w-full text-left table-auto min-w-max">
            <thead>
              <tr>
                {(!switchOn
                  ? TABLE_HEAD.filter((v) => v !== t("m.Tags"))
                  : TABLE_HEAD
                ).map((head) => (
                  <th
                    key={head}
                    className="px-4 py-3 border-b border-blue-gray-100 bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-bold leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {problemList && problemList.length > 0 ? (
                problemList.map(
                  (
                    {
                      problem_number,
                      title,
                      difficulty,
                      total,
                      ac_rate,
                      tags,
                      visible,
                    },
                    index,
                  ) => {
                    const isLast = index === problemList.length - 1;
                    const classes = isLast
                      ? "px-4 py-3"
                      : "px-4 py-3 border-b border-blue-gray-50";

                    return (
                      <tr
                        key={problem_number}
                        className="cursor-pointer hover:bg-gray-50"
                        onClick={() => navigate("/problem/" + problem_number)}
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {problem_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {title}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Chip
                            value={difficulty}
                            className="w-fit"
                            color="blue"
                          />
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {total}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            as="a"
                            href="#"
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {Math.round(ac_rate * 10) / 10}%
                          </Typography>
                        </td>
                        {switchOn && (
                          <td className={classes}>
                            <div className="flex w-20 gap-1">
                              {tags.map((tag) => (
                                <Chip
                                  variant="ghost"
                                  key={tag}
                                  value={tag}
                                  className="w-fit"
                                />
                              ))}
                            </div>
                          </td>
                        )}
                        <td className={classes}>
                          <div
                            className="flex gap-x-5"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Switch
                              color="blue"
                              onChange={(e) => {
                                e.stopPropagation();
                                handelToggleProblemVisible(problem_number);
                              }}
                              checked={visible}
                            />
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteProblem(problem_number);
                              }}
                              className="border border-red-500 w-9 h-7 text-red-500 p-0.5 px-2 rounded-lg"
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  },
                )
              ) : (
                <tr>
                  <td className="p-5 text-center" colSpan={TABLE_HEAD.length}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Panel>
        <div className="flex justify-end p-4">
          <Pagination totalPage={totalPage} />
        </div>
      </section>
      <section className="col-span-5">
        <Panel>
          <Panel.Title>{t("m.Tags")}</Panel.Title>
          <div>
            <ButtonGroup
              variant="outlined"
              color="blue-gray"
              size="sm"
              className="flex flex-wrap gap-2"
            >
              {tags?.map((tag) => (
                <Button
                  name={tag.name}
                  key={tag.id}
                  disabled={tag.name === currentTag}
                  onClick={(e) => setCurrentTag(e.target.name)}
                  className={"border-r rounded-full"}
                >
                  {tag.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </Panel>
      </section>
    </div>
  );
}

export default ProblemList;
