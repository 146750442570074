import React from "react";
import { Button, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { login } from "../../api";
import { useModal } from "../../../../hooks/useModal";
import { useRecoilState } from "recoil";
import { userState } from "../../../../store/states/userState";
import Register from "./Register";
import { WEBSITE_CONFIG } from "../../../../components/TopNav";

function Login() {
  const [, setUserDataState] = useRecoilState(userState);

  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const { register: formRegister, handleSubmit } = useForm();

  const registerModalData = {
    title: `${t("m.Welcome_to")} ${WEBSITE_CONFIG.website_name_shortcut}`,
    content: <Register />,
    size: "xs",
  };

  const handleLogin = ({ loginId, password }) => {
    login({ login_id: loginId, password }).then((res) => {
      if (res?.status === 200) {
        closeModal();
        setUserDataState({
          _id: res.data.user._id,
          username: res.data.user.username,
          isLogin: true,
          role: res.data.user.role,
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div className="flex flex-col space-y-4">
        <Input
          type="text"
          size="lg"
          label={t("m.LoginId")}
          {...formRegister("loginId", { required: true })}
        />
        <Input
          type="password"
          size="lg"
          label={t("m.LoginPassword")}
          {...formRegister("password", { required: true })}
        />
      </div>
      <div className="mt-5 overflow-auto">
        <Button className="w-full mb-4" variant="filled" type="submit">
          {t("m.UserLogin")}
        </Button>
        <Button
          variant="text"
          className="p-0 text-sm font-medium text-blue-600"
          onClick={() => openModal(registerModalData)}
        >
          {t("m.No_Account")}
        </Button>
        <Button
          variant="text"
          className="float-right p-0 text-sm font-medium text-blue-600"
        >
          {t("m.Forget_Password")}
        </Button>
      </div>
    </form>
  );
}

export default Login;
