import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Chip,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Switch } from "@material-tailwind/react";
import Panel from "../../components/Panel";
import { useSearchParams } from "react-router-dom";
import { Result } from "./../../../../common/enum";
import { getSubmissionList } from "../../api";
import { useRecoilState } from "recoil";
import { userState } from "../../../../store/states/userState";

export function SwitchCustomStyles({ checked, onChange }) {
  return (
    <Switch
      color="blue"
      checked={checked}
      onChange={onChange}
      ripple={false}
      className="w-full h-full"
      containerProps={{
        className: "w-14 h-7 -mr-3",
      }}
      label={
        <div className="relative flex items-center">
          <span className="absolute text-[0.6rem] font-medium text-white right-[0.5rem]">
            All
          </span>
          <span className="absolute text-[0.6rem] font-medium text-white right-[1.9rem]">
            Mine
          </span>
        </div>
      }
      circleProps={{
        className: "before:hidden left-1 w-6 border-none",
      }}
    />
  );
}

function Submission() {
  const [submissionList, setSubmissionList] = useState(null);
  const [user] = useRecoilState(userState);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMine = searchParams.get("isMine") === "true";
  const result = searchParams.get("result") || "All";
  const problemNumber = searchParams.get("problemNumber") || null;

  const { t } = useTranslation();

  const TABLE_HEAD = [
    "#",
    "When",
    "Result",
    "Problem",
    "Time",
    "Memory",
    "Language",
    "Submitter",
  ];

  useEffect(() => {
    getSubmissionList({
      problem_number: problemNumber,
      state: result,
      user_id: isMine ? user._id : null,
    }).then((res) => {
      setSubmissionList(res);
    });
  }, [searchParams]);

  const handleResetClick = () => {
    setSearchParams(
      searchParams.get("problemNumber")
        ? { problemNumber: searchParams.get("problemNumber") }
        : {},
    );
  };

  return (
    <section className="col-span-19">
      <Panel>
        <Panel.Title>{t("m.Submissions")}</Panel.Title>
        <Panel.Extra>
          <form className="flex items-center space-x-2">
            <SwitchCustomStyles
              checked={isMine}
              onChange={() => {
                searchParams.set("isMine", !isMine);
                setSearchParams(searchParams);
              }}
            />
            <div className="w-60">
              <Select
                containerProps={{ className: "!min-w-20" }}
                className="z-10"
                label="Status"
                value={result}
                onChange={(newValue) => {
                  searchParams.set("result", newValue);
                  setSearchParams(searchParams);
                }}
              >
                <Option value={t("m.All")}>{t("m.All")}</Option>
                <Option value={"Success"}>{Result.Success.message}</Option>
                <Option value={"WrongAnswer"}>
                  {Result.WrongAnswer.message}
                </Option>
                <Option value={"CpuTimeLimitExceeded"}>
                  {Result.CpuTimeLimitExceeded.message}
                </Option>
                <Option value={"RealTimeLimitExceeded"}>
                  {Result.RealTimeLimitExceeded.message}
                </Option>
                <Option value={"MemoryLimitExceeded"}>
                  {Result.MemoryLimitExceeded.message}
                </Option>
                <Option value={"RuntimeError"}>
                  {Result.RuntimeError.message}
                </Option>
                <Option value={"SystemError"}>
                  {Result.SystemError.message}
                </Option>
                <Option value={"CompileError"}>
                  {Result.CompileError.message}
                </Option>
                <Option value={"JudgeClientError"}>
                  {Result.JudgeClientError.message}
                </Option>
              </Select>
            </div>
            <Button
              className="flex items-center gap-3 h-fit"
              onClick={handleResetClick}
            >
              <ArrowPathIcon strokeWidth={2} className="w-4 h-4" />
              {t("m.Reset")}
            </Button>
          </form>
        </Panel.Extra>
        <table className="w-full text-left table-auto min-w-max">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="px-4 py-3 border-b border-blue-gray-100 bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-bold leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {submissionList && submissionList.length > 0 ? (
              submissionList.map(
                (
                  {
                    created_at,
                    result,
                    problem_num,
                    real_time,
                    memory,
                    language,
                    submitter_name,
                    submitter_id,
                    id,
                  },
                  index,
                ) => {
                  const isLast = index === submissionList.length - 1;
                  const classes = isLast
                    ? "px-4 py-3"
                    : "px-4 py-3 border-b border-blue-gray-50";

                  return (
                    <tr key={id} className="cursor-pointer">
                      <td className={classes}>
                        <Typography
                          as={
                            submitter_id === user._id || user.role === "admin"
                              ? "a"
                              : "div"
                          }
                          href={`/submission/${id}`}
                          variant="small"
                          color={
                            submitter_id === user._id || user.role === "admin"
                              ? "blue"
                              : "black"
                          }
                          className="font-normal"
                        >
                          {id}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-normal">
                          {created_at.split("T")[0] +
                            " " +
                            created_at.split("T")[1].split(".")[0]}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Chip
                          value={Result?.[result].message}
                          className="w-fit"
                          color={Result?.[result].color}
                        />
                      </td>
                      <td className={classes}>
                        <Typography
                          as="a"
                          href={`/problem/${problem_num}`}
                          variant="small"
                          color="blue"
                          className="font-normal"
                        >
                          {problem_num}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-medium">
                          {real_time}ms
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-medium">
                          {memory / 1024 / 1024} mb
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-normal">
                          {language}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-normal">
                          {submitter_name}
                        </Typography>
                      </td>
                    </tr>
                  );
                },
              )
            ) : (
              <tr>
                <td className="p-5 text-center" colSpan={TABLE_HEAD.length}>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Panel>
    </section>
  );
}

export default Submission;
