export const m = {
  Author: "Author",
  Operation: "Operation",
  // SideMenu.vue
  Dashboard: "Dashboard",
  General: "General",
  User: "User",
  Announcement: "Announcement",
  System_Config: "System Config",
  Judge_Server: "Judge Server",
  Prune_Test_Case: "Prune Test Case",
  Problem: "Problem",
  FromFile: "From File",
  ToFile: "To File",
  ShareSubmission: "Share Submission",
  Problem_List: "Problem List",
  Create_Problem: "Create Problem",
  Export_Import_Problem: "Export Or Import Problem",
  Contest: "Contest",
  Contest_List: "Contest List",
  Create_Contest: "Create Contest",
  // User.vue
  User_User: "User",
  Import_User: "Import User",
  Generate_User: "Generate User",
  // User.vue-dialog
  User_Info: "User",
  User_Username: "Username",
  User_Real_Name: "Real Name",
  User_Email: "Email",
  User_New_Password: "New Password",
  User_Type: "User Type",
  Problem_Permission: "Problem Permission",
  Two_Factor_Auth: "Two Factor Auth",
  Is_Disabled: "Is Disabled",
  // Announcement.vue
  General_Announcement: "Announcement",
  Announcement_Title: "Title",
  Announcement_Content: "Content",
  Announcement_visible: "Visible",
  // Conf.vue
  SMTP_Config: "SMTP Config",
  Server: "Server",
  Port: "Port",
  Email: "Email",
  Password: "Password",
  Website_Config: "Web Config",
  Base_Url: "Base Url",
  Name: "Name",
  Shortcut: "Shortcut",
  Footer: "Footer",
  Allow_Register: "Allow Register",
  Submission_List_Show_All: "Submission List Show All",
  // JudgeServer.vue
  Judge_Server_Token: "Judge Server Token",
  Judge_Server_Info: "Judge Server",
  IP: "IP",
  Judger_Version: "Judger Version",
  Service_URL: "Service URL",
  Last_Heartbeat: "Last Heartbeat",
  Create_Time: "Create Time",
  // PruneTestCase
  Test_Case_Prune_Test_Case: "Prune Test Case",
  // ImportAndExport.vue <추가>
  Import_Problem: "Import Problem",
  Choose_File: "Choose File",
  // Problem.vue
  Display_ID: "Display ID",
  Title: "Title",
  Description: "Description",
  Input_Description: "Input Description",
  Output_Description: "Output Description",
  Time_Limit: "Time Limit",
  Memory_limit: "Memory limit",
  Difficulty: "Difficulty",
  Visible: "Visible",
  Languages: "Languages",
  Input_Samples: "Input Samples",
  Output_Samples: "Output Samples",
  Add_Sample: "Add Sample",
  Code_Template: "Code_Template",
  Special_Judge: "Special Judge",
  Use_Special_Judge: "Use Special Judge",
  Special_Judge_Code: "Special Judge Code",
  SPJ_language: "SPJ languagtitlee",
  Compile: "Compile",
  TestCase: "TestCase",
  IOMode: "IO Mode",
  InputFileName: "Input File Name",
  OutputFileName: "Output File Name",
  Type: "Type",
  Input: "Input",
  Output: "Output",
  Score: "Score",
  Hint: "Hint",
  Source: "Source",
  Edit_Problem: "Edit Problem",
  Add_Problem: "Add Problem",
  High: "High",
  Mid: "Mid",
  Low: "Low",
  Tag: "Tag",
  New_Tag: "New Tag",
  // ProblemList.vue
  Contest_Problem_List: "Contest Problem List",
  // Contest.vue
  ContestTitle: "Title",
  ContestDescription: "Description",
  Contest_Start_Time: "Start Time",
  Contest_End_Time: "End Time",
  Contest_Password: "Password",
  Contest_Rule_Type: "Contest Rule Type",
  Real_Time_Rank: "Real Time Rank",
  Contest_Status: "Status",
  Allowed_IP_Ranges: "Allowed IP Ranges",
  CIDR_Network: "CIDR Network",
  // Dashboard.vue
  Last_Login: "Last Login",
  System_Overview: "System Overview",
  DashBoardJudge_Server: "Judge Server",
  HTTPS_Status: "HTTPS Status",
  Force_HTTPS: "Force HTTPS",
  CDN_HOST: "CDN HOST",
  // Login.vue
  Welcome_to_Login: "Welcome to Login",
  GO: "GO",
  username: "username",
  password: "password",
};
