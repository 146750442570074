import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSubmission } from "../../api";
import { Typography } from "@material-tailwind/react";
import {
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-text";

import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const ResultCard = ({ submission }) => {
  const Icon = () =>
    submission.result === "Success" ? (
      <CheckCircleIcon className="h-10 w-10 text-green-500" />
    ) : submission.result === "WrongAnswer" ? (
      <XCircleIcon className="h-10 w-10 text-red-500" />
    ) : (
      <InformationCircleIcon className="h-10 w-10 text-orange-500" />
    );

  const bgColor =
    submission.result === "Success"
      ? "bg-green-50"
      : submission.result === "WrongAnswer"
        ? "bg-red-50"
        : "bg-orange-50";

  const borderColor =
    submission.result === "Success"
      ? "border-green-100"
      : submission.result === "WrongAnswer"
        ? "border-red-100"
        : "border-orange-100";

  const isError =
    submission.result !== "Success" && submission.result !== "WrongAnswer";

  return (
    <div
      className={`flex ${bgColor} p-5 gap-x-5 border ${borderColor} items-center`}
    >
      <Icon />
      <div className="flex flex-col gap-y-5">
        <Typography className="text-3xl">{submission.result}</Typography>
        {isError ? (
          <div className="flex flex-col gap-y-3">
            <Typography className="text-lg">
              Language : {submission.language}
            </Typography>
            <Typography className="text-lg whitespace-pre-wrap">
              {submission.error}
            </Typography>
          </div>
        ) : (
          <div className="flex gap-x-5">
            <Typography className="text-lg">
              Time : {submission.time || 0} ms
            </Typography>
            <Typography className="text-lg">
              Memory : {submission.memory || 0} kb
            </Typography>
            <Typography className="text-lg">
              Language : {submission.language}
            </Typography>
            <Typography className="text-lg">
              Submitter : {submission.submitter_name}
            </Typography>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

const language_mods = {
  c: "c_cpp",
  cpp: "c_cpp",
  py: "python",
  java: "java",
};

const CodeCard = ({ language, code }) => {
  return (
    <AceEditor
      width="100%"
      className="problem-code-input border-b border-r"
      readOnly={true}
      mode={language_mods[language] || "text"}
      theme="tomorrow"
      name="codeInput"
      fontSize={18}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={code}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
};

const SubmissionDetail = () => {
  const { submission_id } = useParams();
  const [submission, setSubmission] = useState(null);

  console.log(submission);

  useEffect(() => {
    getSubmission(submission_id).then((data) => {
      setSubmission(data);
    });
  }, [submission_id]);

  if (!submission) return null;

  return (
    <div className="flex flex-col gap-y-10">
      <ResultCard submission={submission} />
      <CodeCard language={submission.language} code={submission.src} />
    </div>
  );
};

export default SubmissionDetail;
