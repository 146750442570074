import React from "react";
import Panel from "../../components/Panel";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <Panel>
        <Panel.Title>{t("m.Announcements")}</Panel.Title>
      </Panel>
    </div>
  );
}

export default Home;
