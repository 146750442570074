import React from "react";
import Chart from "react-apexcharts";

const PieChart = ({ data, width = 280, height = 280 }) => {
  const chartConfig = {
    type: "donut",
    width: width,
    height: height,
    series: data.map((item) => item.value),
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: data.map((item) => item.color),
      legend: {
        position: "bottom",
      },
      labels: data.map((item) => item.name),
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Total",
                fontFamily: "Inter, sans-serif",
                color: "#000000",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  return `${sum}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
          },
        },
      },
    },
  };

  return <Chart {...chartConfig} />;
};

export default PieChart;
