import React from "react";
import { useTranslation } from "react-i18next";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useModal } from "../hooks/useModal";
import Login from "../pages/oj/views/user/Login";
import Register from "../pages/oj/views/user/Register";
import { useRecoilState } from "recoil";
import { userState } from "../store/states/userState";
import { Link } from "react-router-dom";

export const WEBSITE_CONFIG = {
  website_name: "Foxee Online Judge",
  website_name_shortcut: "FOXEEOJ",
};

function NavList() {
  const { t } = useTranslation();

  return (
    <List className="p-0 mt-4 mb-6 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography as="a" href="/" color="blue-gray" className="font-medium">
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {t("m.Home")}
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/problem"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {t("m.NavProblems")}
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/submission"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          {t("m.NavSubmissions")}
        </ListItem>
      </Typography>
    </List>
  );
}

function TopNav() {
  const [userDataState, setUserDataState] = useRecoilState(userState);
  const [openNav, setOpenNav] = React.useState(false);

  const { t } = useTranslation();
  const { openModal } = useModal();

  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);

  const loginModalData = {
    title: `${t("m.Welcome_to")} ${WEBSITE_CONFIG.website_name_shortcut}`,
    content: <Login />,
    size: "xs",
  };
  const registerModalData = {
    title: `${t("m.Welcome_to")} ${WEBSITE_CONFIG.website_name_shortcut}`,
    content: <Register />,
    size: "xs",
  };

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  return (
    <Navbar className="sticky top-0 z-10 max-w-full rounded-none h-max">
      <div className="flex items-center justify-between text-blue-gray-900">
        <div className="flex items-center">
          <Typography
            as="a"
            href="/"
            variant="h5"
            className="mr-4 cursor-pointer py-1.5 lg:ml-2"
          >
            {WEBSITE_CONFIG.website_name}
          </Typography>
          <div className="hidden lg:block">
            <NavList />
          </div>
        </div>
        {userDataState.isLogin ? (
          <Menu
            open={profileMenuOpen}
            handler={setProfileMenuOpen}
            offset={{ mainAxis: 20 }}
            placement="bottom"
          >
            <MenuHandler>
              <Typography as="div" className="font-medium">
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                  {userDataState.username}
                  <ChevronDownIcon strokeWidth={2.5} />
                </ListItem>
              </Typography>
            </MenuHandler>
            <MenuList className="max-w-screen-xl rounded-xl">
              <MenuItem
                onClick={() =>
                  setUserDataState({
                    isLogin: false,
                    username: "",
                    _id: "",
                    role: "",
                  })
                }
              >
                Logout
              </MenuItem>
              {userDataState.role === "admin" && (
                <MenuItem>
                  <Link to="/admin">Admin</Link>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ) : (
          <div className="hidden gap-2 lg:flex">
            <button
              className="px-4 py-2 font-semibold text-gray-500 bg-transparent border border-gray-500 rounded-full hover:text-blue-500 hover:border-blue-500 hover:border-transparent"
              onClick={() => openModal(loginModalData)}
            >
              {t("m.Login")}
            </button>
            <button
              className="px-4 py-2 font-semibold text-gray-500 bg-transparent border border-gray-500 rounded-full hover:text-blue-500 hover:border-blue-500 hover:border-transparent"
              onClick={() => openModal(registerModalData)}
            >
              {t("m.Register")}
            </button>
          </div>
        )}
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="w-6 h-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="w-6 h-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        <div className="flex items-center w-full gap-2 flex-nowrap lg:hidden">
          <Button
            variant="outlined"
            size="sm"
            color="blue-gray"
            fullWidth
            onClick={() => openModal(loginModalData)}
          >
            {t("m.Login")}
          </Button>
          <Button
            variant="gradient"
            size="sm"
            fullWidth
            onClick={() => openModal(registerModalData)}
          >
            {t("m.Register")}
          </Button>
        </div>
      </Collapse>
    </Navbar>
  );
}

export default TopNav;
